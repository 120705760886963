import { create_element } from 'js/utils';

import './login_page.scss';

const settings = JSON.parse(login_page_settings);

function init(){

    if( settings.type == 'form_center' ) {
        init_form_center();
    }
    else {
        init_form_left();
    }

    add_placeholders();
    render_bg();
    render_logo();
}
init();

function init_form_left(){
    import('./form_left.scss');

    const login = document.getElementById('login');

    // left
    const left_con = create_element({class_name: 'login_left' });
    login.after(left_con);
    
    const left_inner = create_element({class_name: 'login_left_inner', render: left_con });
    left_inner.append(login)
    
    // right
    const right_con = create_element({class_name: 'login_right custom_bg'});
    left_con.after(right_con);
}

function init_form_center(){
    import('./form_center.scss');
}

function add_placeholders(){
    const user_login = document.getElementById('user_login');
    if( user_login ) {
        user_login.placeholder = 'Username or Email Address';
    }
    const user_pass = document.getElementById('user_pass');
    if( user_pass ) {
        user_pass.placeholder = 'Password';
    }
}

function render_bg(){
    if( !settings.bg_image ) return;

    const selector = settings.type == 'form_left' ? '.custom_bg' : 'body';

    document.querySelector(selector).style.backgroundImage = 'url('+settings.bg_image+')';
}

function render_logo(){
    if( !settings.logo ) return;

    const container = document.getElementById('loginform');
    
    const logo_container = create_element({class_name: 'custom_logo'});
    container.prepend(logo_container);

    const logo = create_element({tag: 'img', render: logo_container});
    logo.src = settings.logo;
    
}